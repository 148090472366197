import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
import en from "../messages/en.json";
import fr from "../messages/fr.json";
import es from "../messages/es.json";
import zh from "../messages/zh.json";

export const defaultLocale = "en";

addMessages("en", en);
addMessages("fr", fr);
addMessages("es", es);
addMessages("zh", zh);

init({
  fallbackLocale: defaultLocale,
  initialLocale: getLocaleFromNavigator(),
});
