export const photosPathName = "assets/photos/";
export const photoExtension = ".jpg";
export const preloadNextPlacePhotoCount = 2;
export const preloadPreviousPlacePhotoCount = 1;

const photos = new Map();
const preloadPlaceFirstPhotoCount = 3;

export function getPhotoPath(folderName, index) {
  return photosPathName + folderName + "/" + (index + 1) + photoExtension;
}

function preload(path, onLoadFn) {
  if (!photos.has(path)) {
    const photo = new Image();
    if (onLoadFn) {
      photo.onload = onLoadFn;
    }
    photo.src = path;
    photos.set(path, photo);
  }
}

export function preloadPhotos(folderName, photoCount, firstPhotoOnly) {
  if (firstPhotoOnly) {
    photoCount = Math.min(photoCount, preloadPlaceFirstPhotoCount);
  } else if (photoCount <= preloadPlaceFirstPhotoCount) {
    // first photos have been loaded before, so loading the first ones again is not necessary
    return;
  }

  // TODO: load the photos sequentially using the onload callback
  for (let i = 0; i < photoCount; i++) {
    preload(getPhotoPath(folderName, i));
  }
}

function load(path, onLoadFn) {
  let photo = photos.get(path);
  if (photo === undefined) {
    photo = new Image();
    if (onLoadFn) {
      photo.onload = onLoadFn;
    }
    photo.src = path;
    photos.set(path, photo);
  }

  return photo;
}
