const onKeyPressFns = {};

export const ENTER_KEY = 13;
export const SPACE_KEY = 32;
export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const RIGHT_KEY = 39;
export const DOWN_KEY = 40;

export function registerOnKeyPress(keyCode, fn) {
  if (!Array.isArray(keyCode)) {
    keyCode = [keyCode];
  }

  keyCode.forEach((kc) => {
    if (onKeyPressFns[kc] === undefined) {
      onKeyPressFns[kc] = [];
    }

    onKeyPressFns[kc].push(fn);
  });
}

function onKeyPress(keyCode) {
  if (onKeyPressFns[keyCode] !== undefined) {
    onKeyPressFns[keyCode].forEach((fn) => fn());
  }
}

document.onkeydown = function (e) {
  e = e || window.event;
  e.preventDefault();
  onKeyPress(e.keyCode);
};
