import { places } from "../data/places.json";

places.forEach(function (place) {
  if (place.id) {
    place.arrivalDate = new Date(
      `${place.arrivalDate.substr(0, 4)}-${place.arrivalDate.substr(
        4,
        2
      )}-${place.arrivalDate.substr(6, 2)}`
    );
  }
  place.longitude = parseFloat(place.longitude);
  place.latitude = parseFloat(place.latitude);
  place.zoom = parseInt(place.zoom, 10);
});

export { places };
