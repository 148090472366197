import screenfull from "screenfull";

const mapDefaultWidth = 2395.3;
const mapDefaultHeight = 1472.2;

const RAD2DEG = 180 / Math.PI;
const PI_4 = Math.PI / 4;

const perDegX = 6.3;
const degYMultiplier = 361.17;

const degZeroOffsetXFromMapCenter = 64.85;
const degZeroOffsetYFromMapCenter = -149.4;
const markerOffsetXFromScreenCenterInPercent = 0.35; // right: 15%
const markerOffsetYFromScreenCenterInPercent = 0.4; // bottom: 10%
const mapRatio = mapDefaultWidth / mapDefaultHeight;
const mapScaleMax = 6.5;

/**
 * Latitude in degree to point y for Miller cylindrical projection
 *
 * Mercator projection: https://wiki.openstreetmap.org/wiki/Mercator#JavaScript_.28or_ActionScript.29
 * Miller cylindrical projection: https://mathworld.wolfram.com/MillerCylindricalProjection.html
 * As explained below, degree has to be converted in radian
 * https://www.experts-exchange.com/questions/20755937/Convert-between-long-lat-and-x-y-using-Miller-map-projection.html
 */
function lat2y(lat) {
  return 1.25 * Math.log(Math.tan(PI_4 + (lat / RAD2DEG) * 0.4));
}

let width;
let height;
let screenRatio;

let mapWidth;
let mapHeight;
let mapDefaultScale;

let offsetX;
let offsetY;

let landcapeView;

export function setMapProperties() {
  width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  height =
    document.body.offsetHeight ||
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  screenRatio = width / height;

  landcapeView = screenRatio > 1;

  if (screenRatio < mapRatio) {
    mapWidth = width;
    mapHeight = (mapDefaultHeight * width) / mapDefaultWidth;
  } else {
    mapWidth = (mapDefaultWidth * height) / mapDefaultHeight;
    mapHeight = height;
  }

  mapDefaultScale = mapDefaultWidth / mapWidth;

  offsetX = degZeroOffsetXFromMapCenter / mapDefaultScale;
  offsetY = degZeroOffsetYFromMapCenter / mapDefaultScale;
}

export function calculateMapTransform(latitude, longitude, zoom, centered) {
  // on mobile, detect if the address bar has been concealed
  if (document.body.offsetHeight !== height) {
    setMapProperties();
  }

  zoom = zoom < 1 ? 1 : zoom;
  centered = centered || false;

  let scale = zoom * mapDefaultScale;
  if (scale > mapScaleMax) {
    scale = mapScaleMax;
    zoom = scale / mapDefaultScale;
  }

  let markerOffsetX = 0;
  let markerOffsetY = 0;
  if (!centered) {
    if (landcapeView) {
      markerOffsetX = (width * markerOffsetXFromScreenCenterInPercent) / scale;
    } else {
      markerOffsetY = (height * markerOffsetYFromScreenCenterInPercent) / scale;
    }
  }

  let translateX = perDegX * longitude * zoom * -1;
  let translateY = degYMultiplier * lat2y(latitude) * zoom;

  return `translate(${translateX}px, ${translateY}px) scale(${scale},${scale}) translate(${offsetX}px, ${offsetY}px) translate(${markerOffsetX}px, ${markerOffsetY}px)`;
}
