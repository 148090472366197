import App from "./App.svelte";

import "./i18n.js";
import { places } from "./data-loader.js";

const app = new App({
  target: document.body,
  props: {
    places,
  },
});

export default app;
