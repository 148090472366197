const smartZoomMin = 1;
const smartZoomMax = 2.5;
const smartZoomStep = 0.25;
const smartZoomDistanceThresholdMultiplier = 20; // difference between both coordinates

let smartZoom = smartZoomMin;

export function calculateSmartZoom(places, placeIndex, previousPlaceIndex) {
  if (placeIndex !== previousPlaceIndex) {
    const place = places[placeIndex];
    const previousPlace = places[previousPlaceIndex];
    let zoom = smartZoom || previousPlace.zoom;
    let factor = 0;

    function sumCoordinateDifference(place1, place2) {
      return parseInt(
        Math.abs(place1.latitude - place2.latitude) +
          Math.abs(place1.longitude - place2.longitude),
        10
      );
    }

    if (place.region !== "" && place.region === previousPlace.region) {
      factor++;
    } else if (
      previousPlace.country &&
      (previousPlace.country.includes(place.country[0]) ||
        place.country.includes(previousPlace.country[0]))
    ) {
      factor++;
    }
    
    const sum = sumCoordinateDifference(place, previousPlace);
    if (sum < smartZoomDistanceThresholdMultiplier) {
      factor++;
    } else if (sum < smartZoomDistanceThresholdMultiplier * 2) {
    } else if (sum < smartZoomDistanceThresholdMultiplier * 5) {
      factor--;
    } else if (sum < smartZoomDistanceThresholdMultiplier * 10) {
      factor -= 2;
    } else {
      factor -= 4;
    }

    // diff *= previousPlaceIndex < placeIndex ? 1 : -1;

    smartZoom = Math.max(
      smartZoomMin,
      Math.min(smartZoomMax, zoom + factor * smartZoomStep)
    );
  }

  return smartZoom;
}
