<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { transitionDuration } from "../../data/config.json";
  import {
    setMapProperties,
    calculateMapTransform
  } from "../functions/map-fns";
  import { registerOnResize } from "../functions/resize-fns";

  const mapSrc = "assets/map.svg";
  const mapContentDocumentId = "map";

  const dispatch = createEventDispatcher();

  // elements
  let mapContainerEl;
  let mapEl;

  window.onload = setMapProperties();
  registerOnResize(setMapProperties, true);

  export function updateMapPosition(place) {
    const transitionDuration = mapEl.style.transitionDuration;

    setTimeout(function() {
      mapEl.style.transitionDuration = "0s";

      // TODO: preserve the zoom
      setMapTransform(place);

      setTimeout(function() {
        mapEl.style.transitionDuration = transitionDuration;
      }, 1000);
    }, 0);
  }

  export function setMapTransform(place, zoom) {
    setTimeout(function() {
      mapEl.style.transform = calculateMapTransform(
        place.latitude,
        place.longitude,
        zoom || place.zoom,
        place.id === undefined // center the first place
      );
    }, 0);
  }

  function setMapTransitionStyle(el) {
    setTimeout(function() {
      el.style.transitionProperty = "transform";
      el.style.transitionTimingFunction = "ease-in";
      el.style.transitionDuration = transitionDuration + "s";
    }, 0);
  }

  function setMapTransitionEvents(el) {
    function toggleMapMoving() {
      if (this.style.transitionDuration !== "0s") {
        dispatch("toggleMoving");
      }
    }

    el.addEventListener("transitionstart", toggleMapMoving.bind(el));
    el.addEventListener("transitionend", toggleMapMoving.bind(el));
  }

  onMount(() => {
    mapContainerEl.addEventListener("load", function(event) {
      mapEl = event.target.contentDocument.getElementById(mapContentDocumentId);
      document.body.scrollTop = document.innerHeight;
      setMapTransitionEvents(mapEl);
      setMapTransitionStyle(mapEl);
      dispatch("loaded");
    });
  });
</script>

<style type="text/scss">
  .map-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
</style>

<svelte:options accessors />
<object
  bind:this={mapContainerEl}
  class="map-container"
  title="World Map"
  data={mapSrc}
  type="image/svg+xml" />
