const onResizeFns = [];
const debounceDuration = 500;
let debounceTimer;

export function registerOnResize(fn, callFirst) {
  callFirst = callFirst || false;
  if (callFirst && onResizeFns.length > 0) {
    onResizeFns.unshift(fn);
  } else {
    onResizeFns.push(fn);
  }
}

function debounceOnResize() {
  onResizeFns.forEach((fn) => fn());
}

window.onresize = function () {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(debounceOnResize, debounceDuration);
};
