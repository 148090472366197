<script>
  import { beforeUpdate, createEventDispatcher } from "svelte";
  import { getPhotoPath, preloadPhotos } from "../functions/photo-fns.js";
  import { registerOnKeyPress, SPACE_KEY } from "../functions/key-fns";

  export let photoCount;
  export let folderName;
  export let altText = "Photo";

  const dispatch = createEventDispatcher();

  let photos;
  let currentFolderName;
  let currentCarouselIndex;

  function reset() {
    photos = new Array(photoCount);
    currentFolderName = folderName;
    currentCarouselIndex = 0;
  }

  reset();

  function next() {
    // first time
    if (currentCarouselIndex === 0) {
      preloadPhotos(folderName, photoCount);
      dispatch("photoClicked");
    }
    currentCarouselIndex = (currentCarouselIndex + 1) % photoCount;
  }

  registerOnKeyPress(SPACE_KEY, next);

  beforeUpdate(() => {
    if (currentFolderName !== folderName) {
      reset();
    }
  });
</script>

<style type="text/scss">
  button {
    width: 100%;
    max-height: inherit;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: none;
    background-color: inherit;
    -webkit-tap-highlight-color: transparent;

    img {
      object-fit: contain;
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: inherit;
    }
  }
</style>

<button on:click={next}>
  {#each photos as photo, index}
    {#if index == currentCarouselIndex}
      <img
        src={getPhotoPath(folderName, index)}
        alt={altText + ' ' + (index + 1)} />
    {/if}
  {/each}
</button>
