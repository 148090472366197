<script>
  import { fade, fly } from "svelte/transition";
  import { _ } from "svelte-i18n";
  import screenfull from "screenfull";
  import LocaleSelector from "./components/LocaleSelector.svelte";
  import Map from "./components/Map.svelte";
  import Place from "./components/Place.svelte";
  import {
    transitionDuration,
    useSmartZoom,
    useFullscreen
  } from "../data/config.json";
  import { calculateSmartZoom } from "./functions/zoom-fns";
  import { setMapProperties } from "./functions/map-fns";
  import { registerOnResize } from "./functions/resize-fns";
  import { preloadPhotos } from "./functions/photo-fns";
  import {
    registerOnKeyPress,
    DOWN_KEY,
    UP_KEY,
    LEFT_KEY,
    RIGHT_KEY,
    ENTER_KEY
  } from "./functions/key-fns";

  export let places;

  let currentPlaceIndex = 0;
  let mapComponent;

  // flags
  let mapLoaded = false;
  let mapMoving = false;
  let started = false;
  let carouselPhotoClicked = false;
  let helperDisplayed = false;

  // functions
  const next = moveMapToNextPlace;
  const prev = moveMapToPreviousPlace;
  const start = function() {
    if (useFullscreen && screenfull.isEnabled) {
      screenfull.request();
      // moveMapToNextPlace will be called by afterResize
      // if it fails, the moveMapToNextPlace will still be called
      screenfull.on("error", function() {
        moveMapToNextPlace();
        screenfull.off("error");
      });
      screenfull.on("change", function() {
        window.dispatchEvent(new Event("resize"));
        screenfull.off("change");
      });
    } else {
      moveMapToNextPlace();
    }

    setTimeout(function() {
      started = true;
    }, 1000);
  };

  function displayHelper() {
    helperDisplayed = true;

    setTimeout(function() {
      helperDisplayed = false;
    }, 5000);
  }

  function preloadPreviousAndNextPlaceFirstPhotos() {
    let i = 0;
    let j = 0;
    while (!preloadFirstPhotos(places[nthPlaceFromIndex(--i)])) {}
    while (!preloadFirstPhotos(places[nthPlaceFromIndex(++j)])) {}
    while (!preloadFirstPhotos(places[nthPlaceFromIndex(++j)])) {}
  }

  function preloadFirstPhotos(place) {
    if (place.id === undefined) {
      return false;
    }

    preloadPhotos(place.id, place.photoCount, true);
    return true;
  }

  function onMapLoaded() {
    moveMapTo();

    registerOnKeyPress(ENTER_KEY, start);
    registerOnKeyPress([LEFT_KEY, UP_KEY], function() {
      if (started && !mapMoving) {
        moveMapToPreviousPlace();
      }
    });
    registerOnKeyPress([RIGHT_KEY, DOWN_KEY], function() {
      if (started && !mapMoving) {
        moveMapToNextPlace();
      }
    });

    setTimeout(function() {
      mapLoaded = true;
    }, 1000);
  }

  function onToggleMapMoving() {
    setTimeout(function() {
      mapMoving = !mapMoving;
    }, 0);
  }

  function onCarouselPhotoClicked() {
    carouselPhotoClicked = true;
  }

  function nthPlaceFromIndex(n) {
    if (n >= 0) {
      return (n + currentPlaceIndex) % places.length;
    } else {
      return (n + places.length + currentPlaceIndex) % places.length;
    }
  }

  function moveMapTo(index) {
    index = index === undefined ? currentPlaceIndex : index;
    let place = places[index];
    if (started && place.id === undefined) {
      return false;
    }

    mapComponent.setMapTransform(
      place,
      useSmartZoom && calculateSmartZoom(places, index, currentPlaceIndex)
    );

    setTimeout(function() {
      currentPlaceIndex = index;

      if (started && !carouselPhotoClicked && currentPlaceIndex % 2 === 0) {
        displayHelper();
      }
    }, 1000);

    return true;
  }

  function moveMapToPreviousPlace() {
    let i = 0;
    let previousPlaceIndex;
    do {
      previousPlaceIndex = nthPlaceFromIndex(--i);
    } while (!moveMapTo(previousPlaceIndex));
    while (!preloadFirstPhotos(places[nthPlaceFromIndex(--i)])) {}
  }

  function moveMapToNextPlace() {
    let i = 0;
    let nextPlaceIndex;
    do {
      nextPlaceIndex = nthPlaceFromIndex(++i);
    } while (!moveMapTo(nextPlaceIndex));
    i++; // the next one has already been loaded so we load the one after
    while (!preloadFirstPhotos(places[nthPlaceFromIndex(++i)])) {}
  }

  registerOnResize(function() {
    if (started && !mapMoving) {
      mapComponent.updateMapPosition(places[currentPlaceIndex]);
    } else if (!started && useFullscreen && screenfull.isFullscreen) {
      moveMapToNextPlace();
    }
  });

  preloadPreviousAndNextPlaceFirstPhotos();
</script>

<style type="text/scss">
  main {
    display: grid;
    width: inherit;
    height: inherit;

    .background,
    .overlay {
      grid-area: 1 / 1;
    }

    .background {
      height: inherit;
    }

    .overlay {
      z-index: 9;

      .start-button,
      .prev-button,
      .next-button {
        padding: 0;
        border: none;
        font-family: inherit;
        background-color: #523c30;
        color: #fff2df;
      }
      .prev-button,
      .next-button {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 18px 0 92px 0;
        width: 180px;
        height: 180px;
        border-radius: 90px;
        bottom: -90px;

        .material-icons {
          font-size: 32px;
          flex-grow: 1;
        }

        .text {
          display: block;
          text-align: center;
          width: 84px;
          line-height: 1;
          font-size: 16px;
        }
      }

      .prev-button {
        left: -90px;
        padding-left: 90px;

        .material-icons {
          padding-left: 20px;
        }

        .text {
          padding-right: 6px;
        }
      }

      .next-button {
        right: -90px;

        .material-icons {
          padding-left: 44px;
        }

        .text {
          padding-left: 6px;
        }
      }

      .marker {
        font-size: 64px;
        position: absolute;
        bottom: calc(10% - 5px);
        left: calc(50% - 32px);
      }

      .helper {
        position: absolute;
        bottom: 5%;
        background-color: #523c30;
        color: #fff2df;
        width: 30vw;
        line-height: 1;
        font-size: 24px;
        text-align: center;
        left: calc(50% - 15vw - 6px);
        padding: 18px 6px 6px 6px;
        border-radius: 12px;
      }

      @media screen and (orientation: landscape) {
        @media (max-height: 800px) {
          .prev-button {
            left: unset;
            flex-direction: column-reverse;
            top: -90px;
            right: -90px;
            padding-top: 98px;
            padding-bottom: 18px;
            padding-left: unset;

            .material-icons {
              flex-grow: 0;
              padding-left: 44px;
            }

            .text {
              padding-right: unset;
              width: 84px;
              padding-left: 6px;
            }
          }
        }

        .marker {
          right: calc(15% - 32px);
          bottom: calc(50% - 5px);
          left: unset;
        }
      }

      .start-container {
        text-align: center;

        .title {
          margin-top: 20vh;
          font-size: 72px;
          line-height: 104px;
        }

        .start-button {
          position: absolute;
          font-size: 20px;
          line-height: 24px;
          width: 120px;
          height: 120px;
          border-radius: 60px;
          left: calc(50% - 60px);
          bottom: 20vh;
        }
      }
    }
  }
</style>

<svelte:head>
  <title>{$_('meta.title')}</title>
</svelte:head>

<main>
  <div class="background">
    <Map
      bind:this={mapComponent}
      on:loaded|once={onMapLoaded}
      on:toggleMoving={onToggleMapMoving} />
  </div>
  <div class="overlay">
    {#if mapLoaded}
      {#if started}
        <nav transition:fade>
          <button
            on:click={moveMapToPreviousPlace}
            disabled={mapMoving}
            class="prev-button"
            aria-label={$_('buttons.prev')}>
            <span class="material-icons">flight_land</span>
            <span class="text">{$_('buttons.prev')}</span>
          </button>
          <button
            on:click={moveMapToNextPlace}
            disabled={mapMoving}
            class="next-button"
            aria-label={$_('buttons.next')}>
            <span class="material-icons">flight_takeoff</span>
            <span class="text">{$_('buttons.next')}</span>
          </button>
        </nav>
        {#if !mapMoving}
          <div transition:fade>
            <span class="material-icons marker">place</span>
            <Place
              {...places[currentPlaceIndex]}
              on:photoClicked|once={onCarouselPhotoClicked} />
          </div>
          {#if helperDisplayed}
            <div
              in:fly={{ y: 50, delay: 1000, duration: 500 }}
              out:fade
              class="helper">
              {$_('helpers.photos.click')}
            </div>
          {/if}
        {/if}
      {:else if !mapMoving}
        <div transition:fade class="start-container">
          <LocaleSelector />
          <div class="title">{$_('meta.title')}</div>
          <button on:click={start} class="start-button">
            {$_('buttons.start')}
          </button>
        </div>
      {/if}
    {/if}
  </div>
</main>
